/**
 * @description This is the view layer for the ImageRows component.
 * Component is used in ImageRows under home screen
 * @author  carlosintrigue
 */
import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import Container from "components/Container";
import ContainerHalf from "components/ContainerHalf";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";

import Markdown from "components/Markdown";

const customMarker = L.icon({ iconUrl: "/assets/images/map-pin-white.png" });
const customMarker2 = L.icon({ iconUrl: "/assets/images/map-pin-red.png" });

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: "black"
    },
    row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexFlow: "row wrap"
    },
    column: {
      flex: "50%",
      "& p": {
        textAlign: "left",
        font: "500 16px/22px Avenir",
        letterSpacing: "0",
        color: "#000000",
        opacity: "0.75"
      }
    },
    "@media (max-width: 768px)": {
      column: {
        flex: "1 1 100%"
      },
      columnText: {
        paddingTop: "7%",
        paddingBottom: "7%"
      },
      title: {
        font: "Bold 36px/42px Coast !important"
      },
      md: {
        margin: "20px 0px 20px 0px"
      }
    },
    columnText: {
      paddingRight: "7%",
      paddingLeft: "7%"
    },
    backgroundImage: {
      width: "100%"
    },
    title: {
      font: "700 48px/56px Coast",
      marginBottom: "24px",
      marginTop: "48px",
      textAlign: "left"
    },
    rowTitle: {
      font: "900 24px/33px Avenir",
      marginBottom: "12px"
    },
    conditions: {
      font: "300 10px/14px Avenir",
      marginTop: "12px"
    },
    linkText: {
      marginTop: "24px",
      font: "500 16px/22px Avenir",
      color: "#007AB1",
      cursor: "pointer"
    },
    md: {
      "& h1": {
        textAlign: "left",
        font: "700 24px/33px Avenir",
        letterSpacing: "0",
        color: "#000000"
      },
      "& h2": {
        textAlign: "left",
        font: "700 18px/25px Avenir",
        letterSpacing: "0",
        color: "#000000"
      },
      "& p": {
        textAlign: "left",
        font: "400 16px/22px Avenir",
        letterSpacing: "0",
        color: "#000000",
        opacity: "0.75"
      },
      "& a": {
        textAlign: "left",
        font: "400 16px/22px Avenir",
        color: "#007AB1"
      }
    },
    leafletContainer: {
      "& p": {
        marginTop: "8px !important",
        marginBottom: "8px !important"
      }
    }
  };
});
const position = [43.565984, -80.921400];
const locations = [
  {
    latLon: [43.565984, -80.921400],	  
    content: `### Head Office
16 Mill St.E.

P.O. Box 70 Milverton, ON 

N0K 1M0`
  },
  {
    latLon: [43.36834, -80.98108],
    content: `### Stratford 
140 Downie St.

Stratford, ON

N5A 1X1
    `
  },
  {
    latLon: [43.754917, -80.67139],
    content: `### Drayton 
21 Wellington St. S.

Unit 4

Drayton, ON

N0G 1P0
`
  },
  {
    latLon: [43.469497, -80.764132],
    content: `### Wellesley 
2196 Gerber Road

Wellesley, ON

N0B 2T0
    `
  }
];

const centre = locations
  .reduce(
    (acc, loc) => {
      return [acc[0] + loc.latLon[0], acc[1] + loc.latLon[1]];
    },
    [0, 0]
  )
  .map(function (item) {
    return item / locations.length;
  });

function ImageRows(props) {
  const classes = useStyles(props);
  const { rows, title } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTabletOrDesktop = useMediaQuery({ query: "(min-width: 769px)" });
  return (
    <div className={classes.root}>
      <Container>
        <h2 className={classes.title}>{title}</h2>
      </Container>
      {/* Mobile */
        isMobile &&
        rows.map((row, index) => {
          return (
            <div className={classes.row}>
              <div
                className={classes.column}
                style={{
                  background: `linear-gradient(90deg, #AE264940 0%,  #007FB840 100%)
                  , url(${row.imageSrc}) no-repeat center center`,
                  backgroundSize: "cover",
                  height: "350px"
                }}
              >
                <Map
                  center={centre}
                  zoom={8}
                  style={{ width: "inherit", height: "350px" }}
                  dragging={false}
                  scrollWheelZoom={false}
                  doubleClickZoom={false}
                  keyboard={true}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />

                  {/* <Marker position={position}>
                    <Popup>
                      A pretty CSS3 popup.
                      <br />
                      Easily customizable.
                    </Popup>
                  </Marker> */}
                  {locations.map(function (item, i) {
                    //console.log(item);
                    return (
                      <Marker position={item.latLon} icon={customMarker2}>
                        <Popup>
                          <div className={classes.leafletContainer} id="test">
                            <Markdown escapeHtml={false}>
                              {item.content}
                            </Markdown>
                          </div>
                        </Popup>
                      </Marker>
                    );
                  })}
                </Map>
              </div>
              <div className={classes.column}>
                <div className={` ${classes.md}`}>
                  <ContainerHalf>
                    <Markdown escapeHtml={false}>{row.content}</Markdown>
                  </ContainerHalf>
                </div>
              </div>
            </div>
          );
        })}
      {/* Desktop or tablet */
        !isMobile &&
        isTabletOrDesktop &&
        rows.map((row, index) => {
          if (row.imageLeft) {
            return (
              <div className={classes.row}>
                <div
                  className={classes.column}
                  style={{
                    height: "400px",

                    overflow: "hidden"
                  }}
                >
                  {" "}
                  <Map
                    center={centre}
                    zoom={9}
                    style={{ width: "inherit", height: "400px" }}
                    dragging={true}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />

                    {locations.map(function (item, i) {
                      //console.log(item);
                      return (
                        <Marker position={item.latLon} icon={customMarker2}>
                          <Popup>
                            <div className={classes.leafletContainer} id="test">
                              <Markdown escapeHtml={false}>
                                {item.content}
                              </Markdown>
                            </div>
                          </Popup>
                        </Marker>
                      );
                    })}
                  </Map>{" "}
                </div>
                <div className={classes.column}>
                  <div className={` ${classes.md}`}>
                    {/* <div className={classes.rowTitle}>{row.title}</div>
                    <div>{row.description}</div>
                    <div className={classes.conditions}>{row.conditions}</div>
                    <div className={classes.linkText}>
                      <div onClick={row.onClick}>{row.linkText}</div>
                    </div> */}
                    <ContainerHalf>
                      <Markdown escapeHtml={false}>{row.content}</Markdown>
                    </ContainerHalf>{" "}
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className={classes.row}>
                <div className={classes.column}>
                  <div className={`${classes.columnText} ${classes.md}`}>
                    {/* <div className={classes.rowTitle}>{row.title}</div>
                    <div>{row.description}</div>
                    <div className={classes.conditions}>{row.conditions}</div>
                    <div className={classes.linkText}>
                      <div onClick={row.onClick}>{row.linkText}</div>
                    </div> */}
                    <ContainerHalf>
                      <Markdown escapeHtml={false}>{row.content}</Markdown>
                    </ContainerHalf>{" "}
                  </div>
                </div>
                <div
                  className={classes.column}
                  style={{
                    background: `linear-gradient(90deg, #AE264940 0%,  #007FB840 100%)
                  , url(${row.imageSrc}) no-repeat center center`,
                    backgroundSize: "cover",
                    height: "400px"
                  }}
                >
                  {/* <img
                    src={row.imageSrc}
                    className={classes.backgroundImage}
                  ></img> */}
                </div>
              </div>
            );
          }
        })}
    </div>
  );
}

ImageRows.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      imageSrc: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      conditions: PropTypes.string,
      linkText: PropTypes.string,
      onClick: PropTypes.func,
      imageLeft: PropTypes.bool
    })
  ),
  title: PropTypes.string
};

ImageRows.defaultProps = {
  rows: [
    {
      imageSrc: "/assets/images/gaming.png",
      title: "Bundle and save.",
      description:
        "Why have multiple providers when you could have just one? With up to $25 in savings per month, Mornington offers you all of the services you need to make your life simpler in one place.",
      linkText: "Explore the Discounts",
      conditions:
        "* Some conditions may apply. Please review our policy to confirm your eligibility.",
      onClick: function () {
        // alert("click");
      },
      imageLeft: true
    },
    {
      imageSrc: "/assets/images/family-watching-tv.png",
      title: "TV Everywhere.",
      description:
        "Watch your favourite shows on your laptop, tablet or smartphone for free with your Mornington TV account. From Disney Junior to A&E, there’s a channel for everyone in the family while you’re travelling or at curled up at home.",
      linkText: "Discover the Channels",
      onClick: function () {
        ////  alert("click") ;
      },
      imageLeft: false
    },
    {
      imageSrc: "/assets/images/co-operative.png",
      title: "Join a co-operative. Join a community.",
      description:
        "Each time you subscribe to one of Mornington’s services, you are not just becoming a customer; you are making an investment in a company you own. As an owner of the co-operative, you receive unique benefits you wouldn’t get from a big name company.",
      linkText: "See the Difference",
      conditions:
        "* Some conditions may apply. Please review our policy to confirm your eligibility.",
      onClick: function () {
        // alert("click");
      },
      imageLeft: true
    },
    {
      imageSrc: "/assets/images/nature-sky-clouds-field.png",
      title: "Go paperless. Pay online.",
      description:
        "Help reduce your carbon footprint and save paper by switching to paperless billing. E-bills are available on the first of each month. No more lost mail, no more piles of unknown origins.",
      linkText: "Go Green Now",
      onClick: function () {
        // alert("click");
      },
      imageLeft: false
    }
  ],
  title: "See the Mornington difference."
};

export default ImageRows;
