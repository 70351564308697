import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

import BannerImage from "components/BannerImage";
import TVGrid from "components/TVGrid";
import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

const testData = [
  {
    title: "Fibre",
    downloadSpeed: "1 Gbps",
    uploadSpeed: "500 Mbps",
    monthlyUsage: "Unlimited",
    onClick: function() {
      // //console.log("phone card button clicked");
    }
  },
  {
    title: "DSL",
    downloadSpeed: "20 Mbps",
    uploadSpeed: "1 Mbps",
    monthlyUsage: "Unlimited",
    onClick: function() {
      // //console.log("phone card button clicked");
    }
  },
  {
    title: "Wireless",
    downloadSpeed: "8 Mbps",
    uploadSpeed: "2 Mbps",
    monthlyUsage: "Unlimited",
    onClick: function() {
      // //console.log("phone card button clicked");
    }
  },
  {
    title: "Resell Internet",
    downloadSpeed: "1 Gbps",
    uploadSpeed: "500 Mbps",
    monthlyUsage: "Unlimited",
    onClick: function() {
      // //console.log("phone card button clicked");
    }
  }
];

function View(props) {
  const classes = useStyles(props);

  const history = useHistory();
  let path = history.location.pathname;
  let urlTail = path.split("/").filter(function(item) {
    return item;
  });
  urlTail = urlTail.join(" > ");

  const { data } = props;

  //console.log("gql", data);
  return (
    <div className={classes.root}>
      <BannerImage
        topText={data.mainBanner[0].breadcrumb}
        title={data.mainBanner[0].title}
        markdown={data.mainBanner[0].body}
        imageUrl={getStrapiURL(data.mainBanner[0].image.src[0].url)}
        gradient
      />
      {/* <div className={classes.containedComponent}>  shop/internet/dsl */}
      <TVGrid
        title={data.Title}
        options={data.Card.map(function(item) {
          return { ...item, imageUrl: item.images[0]?.src[0]?.url };
        })}
      />
      {/* </div> */}

      <BannerImage
        topText={data.Ads[0].ad_category}
        header={true}
        headerText={data.Ads[0].title}
        markdown={data.Ads[0].body}
        description={
          "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
        }
        imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
        ctaButton
        ctaButtonProps={{
          children: data.Ads[0].button_text,
          onClick: function() {
            history.push("/bundle-and-save");
            
          }
        }}
        gradient
        leftAlign={true}
      />
    </div>
  );
}
export default View;
