import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import MobileNavItem from "../MobileNavItem";
import MobileNavDropdown from "../MobileNavDropdown";
import posed from "react-pose";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      width: "100vw",
      height: "inherit",
      // backgroundColor: "red",
      left: 0,
      position: "absolute",
      overflow: "hidden",
      padding: "0px 16px 0px 16px",
      borderTop: "1px solid #CCCCCC"
    },
    menuSwitcher: {
      position: "relative",
      zIndex: 10,
      display: "flex",
      width: "100%",
      padding: "0px 0px 0px 0px",
      borderBottom: "1px solid #CCCCCC"
      // borderTop: "1px solid #CCCCCC"
    },
    submenu: {
      // display: "flex",
      // backgroundColor: "#E6E6E6 !important"
    },
    li: {
      display: "flex",
      position: "relative",
      flexDirection: "column",
      paddingLeft: "0",
      marginBottom: "0",
      listStyle: "none",
      zIndex: 0
    }
  };
});

const AnimatedLi = posed.li({
  closed: {
    top: -100,
    opacity: 0,
    flip: true,
    transition: {
      duration: 100,
      ease: "easeIn"
    }
  },
  open: {
    top: 0,
    opacity: 1,
    flip: true,
    transition: {
      duration: 200,
      ease: "easeIn"
    }
  }
});

function MobileNav2(props) {
  //console.log(props);

  const classes = useStyles(props);

  return (
    <div>
      {props.sections.map(function (item) {
        return (
          <div classes={classes.submenu}>
            <MobileNavItem>{item.content}</MobileNavItem>{" "}
          </div>
        );
      })}
    </div>
  );
}

const URL_LOOKUP = {
  "Which one is right for me?": "/in"
};

function MobileNav(props) {
  const classes = useStyles(props);
  //console.log(props);

  // const { isPersonal } = props;

  const history = useHistory();

  const ip = !history.location.pathname.includes("/business");

  const [isPersonal, setPersonal] = useState(ip);
  alert("h");
  return (
    <div className={classes.root}>
      <div className={classes.menuSwitcher}>
        <MobileNavItem
          active={isPersonal}
          variant={"primary"}
          large
          enableUnderline
          onClick={function () {
            alert("test");
            setPersonal(true);
          }}
        >
          Personal
        </MobileNavItem>
        <MobileNavItem
          active={!isPersonal}
          variant={"primary"}
          large
          enableUnderline
          onClick={function () {
            setPersonal(false);
          }}
        >
          Business
        </MobileNavItem>
      </div>
      {
        <div>
          <li className={classes.li}>
            <MobileNavItem paddingLeft={0} variant={"primary"} large>
              <strong> Home</strong>
            </MobileNavItem>
          </li>

          {/* <AnimatedLi className={classes.li}> */}
          <MobileNavDropdown
            paddingLeft={0}
            large
            enableUnderline
            OptionsComponent={({ paddingLeft }) => (
              <>
                <MobileNavDropdown
                  paddingLeft={25}
                  OptionsComponent={({ paddingLeft }) =>
                    [
                      {
                        content: "Phones",
                        href: "/shop/mobile/phones",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/mobile/phones");
                        }
                      },
                      {
                        content: "Add-Ons",
                        href: "/shop/mobile/add-ons",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/mobile/add-ons");
                        }
                      }
                    ].map(function (item) {
                      return (
                        <li className={`${classes.li} ${classes.submenu}`}>
                          <MobileNavItem
                            paddingLeft={50}
                            variant={"primary"}
                            onClick={item.onClick}
                          >
                            <strong> {item.content} </strong>
                          </MobileNavItem>
                        </li>
                      );
                    })
                  }
                >
                  <strong>Mobile</strong>
                </MobileNavDropdown>

                <MobileNavDropdown
                  paddingLeft={25}
                  OptionsComponent={({ paddingLeft }) =>
                    [
                      {
                        content: "Which one is right for me?",
                        href: "shop/internet",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet");
                        }
                      },
                      {
                        content: "Fibre",
                        href: "/shop/internet/fibre",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet/fibre");
                        }
                      },
                      {
                        content: "DSL",
                        href: "/shop/internet/dsl",

                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet/dsl");
                        }
                      },
                      {
                        content: "Wireless",
                        href: "/shop/internet/wireless",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet/wireless");
                        }
                      },
                      {
                        content: "Web Hosting",
                        href: "/shop/internet/webhosting",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet/webhosting");
                        }
                      },
                      {
                        content: "Resell Internet",
                        href: "/shop/internet/resell",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet/resell");
                        }
                      }
                    ].map(function (item) {
                      return (
                        <li className={classes.li}>
                          <MobileNavItem
                            paddingLeft={50}
                            variant={"primary"}
                            onClick={item.onClick}
                          >
                            <strong> {item.content} </strong>
                          </MobileNavItem>
                        </li>
                      );
                    })
                  }
                >
                  <strong>Internet</strong>
                </MobileNavDropdown>

                <MobileNavDropdown
                  paddingLeft={25}
                  OptionsComponent={({ paddingLeft }) =>
                    [
                      {
                        content: "Packages",
                        href: "/shop/tv/packages",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/tv/packages");
                        }
                      },
                      {
                        content: "Digital Boxes",
                        href: "/shop/tv/digital-boxes",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/tv/digital-boxes");
                        }
                      },
                      {
                        content: "On Demand",
                        href: "/shop/tv/on-demand",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/tv/on-demand");
                        }
                      },
                      {
                        content: "TV Everywhere",
                        href: "/shop/tv/tv-everywhere",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/tv/tv-everywhere");
                        }
                      },
                      {
                        content: "Free Previews",
                        href: "/shop/tv/free-previews",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/tv/free-previews");
                        }
                      }
                    ].map(function (item) {
                      return (
                        <li className={classes.li}>
                          <MobileNavItem
                            paddingLeft={50}
                            variant={"primary"}
                            onClick={item.onClick}
                            href={item.href}
                          >
                            <strong> {item.content} </strong>
                          </MobileNavItem>
                        </li>
                      );
                    })
                  }
                >
                  <strong>TV</strong>
                </MobileNavDropdown>

                <MobileNavDropdown
                  paddingLeft={25}
                  OptionsComponent={({ paddingLeft }) =>
                    [
                      {
                        content: "Rates",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/home-phone/rates");
                        }
                      },
                      {
                        content: "Features",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/home-phone/features");
                        }
                      },
                      {
                        content: "Long Distance",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/home-phone/long-distance");
                        }
                      },
                      {
                        content: "International Calling Rates",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push(
                            "/shop/home-phone/international-calling-rates"
                          );
                        }
                      }
                    ].map(function (item) {
                      return (
                        <li className={classes.li}>
                          <MobileNavItem
                            paddingLeft={50}
                            variant={"primary"}
                            onClick={item.onClick}
                          >
                            <strong> {item.content} </strong>
                          </MobileNavItem>
                        </li>
                      );
                    })
                  }
                >
                  <strong>Home Phone</strong>
                </MobileNavDropdown>

                <MobileNavDropdown
                  paddingLeft={25}
                  OptionsComponent={({ paddingLeft }) =>
                    [
                      {
                        content: "Services",
                        href: "/shop/home-security/services",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/home-security/services");
                        }
                      },
                      {
                        content: "Installation",
                        href: "/shop/home-security/installation",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/home-security/installation");
                        }
                      }
                    ].map(function (item) {
                      return (
                        <li className={classes.li}>
                          <MobileNavItem
                            paddingLeft={50}
                            variant={"primary"}
                            onClick={item.onClick}
                            href={item.href}
                          >
                            <strong> {item.content} </strong>
                          </MobileNavItem>
                        </li>
                      );
                    })
                  }
                >
                  <strong>Home Security</strong>
                </MobileNavDropdown>
              </>
            )}
          >
            <strong>Shop</strong>
          </MobileNavDropdown>
          {/* </AnimatedLi> */}

          <MobileNavItem paddingLeft={0} large variant={"primary"}>
            <strong>Live Support</strong>
          </MobileNavItem>

          <MobileNavDropdown
            paddingLeft={0}
            large
            enableUnderline
            OptionsComponent={({ paddingLeft }) =>
              [
                {
                  content: "Get in Touch",
                  href: "/contact-us/get-in-touch",
                  onClick: function (e) {
                    e.preventDefault();
                    history.push("/contact-us/get-in-touch");
                  }
                },
                {
                  content: "Our Locations",
                  href: "/contact-us/our-locations",
                  onClick: function (e) {
                    e.preventDefault();
                    history.push("/contact-us/our-locations");
                  }
                },
                {
                  content: "Live Support",
                  href: "/contact-us/live-support",
                  onClick: function (e) {
                    e.preventDefault();
                    history.push("/contact-us/live-support");
                  }
                }
              ].map(function (item) {
                return (
                  <li className={classes.li}>
                    <MobileNavItem
                      paddingLeft={25}
                      variant={"primary"}
                      onClick={item.onClick}
                      href={item.href}
                    >
                      <strong> {item.content} </strong>
                    </MobileNavItem>
                  </li>
                );
              })
            }
          >
            <strong>Contact us</strong>
          </MobileNavDropdown>
        </div>
      }
    </div>
  );
}

MobileNav.propTypes = {
  isPersonal: PropTypes.bool
};

MobileNav.defaultProps = {
  isPersonal: true
};

export default MobileNav;
