import React from "react";
import { createUseStyles } from "react-jss";
import SearchInput from "components/SearchInput";
import Typeahead from "components/Typeahead";
import { useHistory } from "react-router-dom";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      overflow: "hidden",
      height: "inherit",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      maxWidth: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      padding: "17px 24px 17px 24px",
      borderTop: "1px solid #CCCCCC",
      boxSizing: "border-box",
      height: 150,
      overflow: "hidden"
    },
    containers: {
      width: "inherit",
      maxWidth: "75%",
      position: "absolute",
      "& > *:not(:last-child)": {
        marginBottom: 16
      }
    }
  };
});

const locations = [
  "Atwood",
  "Bamberg",
  "Drayton",
  "Gadshill",
  "Hawkesville",
  "Linwood",
  "Milverton",
  "Monkton",
  "Moorefield",
  "Nithburg",
  "Wellesley",
  "Other areas"
];

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation } = props;
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.containers}>
        {" "}
        <SearchInput
          onEnterKey={function(val) {
            history.push(`/search/${val}`);
          }}
          onSearchIconClick={function(val) {
            history.push(`/search/${val}`);
          }}
        />
        <div className={classes.fieldContainer}>
          <Typeahead
            onSelection={setLocation}
            value={location}
            locations={locations.map(item => ({
              label: item
            }))}
            variant={props.variant}
          />
        </div>
      </div>
    </div>
  );
}
export default View;
