import gql from "graphql-tag";
import View from "./view";
import { graphql } from "@apollo/react-hoc";

const GET_PRODUCTS = gql`
  {
    products {
      title
      description
      os
      manufacturer
      price
      title
      image_src
      id
    }
  }
`;
const GraphContainer = graphql(GET_PRODUCTS)(View);

export default GraphContainer;
