import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

import BannerImage from "components/BannerImage";
import Footer from "components/Footer";
import Header from "components/Header";
import usePage from "hooks/usePage";
import SearchResults from "components/SearchResults";

import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const localClasses = useStyles(props);
  const { data } = props;

  const [classes, contentEl, footerEl] = usePage();

  const history = useHistory();

  let term = history.location.pathname.substring(
    "/search".length,
    history.location.pathname.length
  );
  if (term.length) {
    term = term.substring(1, term.length);
  }

  // const filtered = plans.filter(
  //   ob =>
  //     ob.planName !== "Command Centre" && ob.planName !== "Command Centre Plus"
  // );

  // const commandCentre = plans.filter(ob => ob.planName === "Command Centre");

  // const commandCentrePlus = plans.filter(
  //   ob => ob.planName === "Command Centre Plus"
  // );

  //console.log("PLANS", history);

  // alert("this");
  return (
    <>
      <header className={classes.header}>
        <Header variant={"primary"} personal />
      </header>
      <main className={classes.content} ref={contentEl} id="content">
        <div className={localClasses.root}>
          <div className={localClasses.root}>
            <BannerImage
              topText={data.mainBanner[0]?.breadcrumb}
              title={data.mainBanner[0]?.title}
              markdown={data.mainBanner[0]?.body}
              imageUrl={getStrapiURL(data.mainBanner[0]?.image.src[0].url)}
              gradient
              searchInput={true}
              searchInputProps={{ value: term }}
              setSearchInput={function(val) {
                history.push("/search/" + val);
              }}
            />
            <br />
            <SearchResults searchInput={term} />
            <BannerImage
              header={true}
              headerText={data.Ads[0].title}
              markdown={data.Ads[0].body}
              imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
              ctaButton
              ctaButtonProps={{
                children: data.Ads[0].button_text,
                onClick: function() {
                  ////  alert("click") ;
                }
              }}
              gradient
              leftAlign={true}
            />
          </div>
        </div>
      </main>
      <footer className={classes.footer} ref={footerEl}>
        <Footer />
      </footer>
    </>
  );
}
export default View;
