import React, { useState } from "react";
import Footer from "components/Footer";
import Header from "components/Header";
import SubRouting from "router/subrouting.js";
import { createUseStyles } from "react-jss";
import { Switch } from "react-router-dom";
import usePage from "hooks/usePage";
import Container from "components/Container";
import Typography from "components/Typography";
import BannerImage from "components/BannerImage";
import FullTextRow from "components/FullTextRow";

import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      paddingTop: 44,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function Legal(props) {
  const [classes, contentEl, footerEl] = usePage();
  const { data } = props;

  const localClasses = useStyles(props);
  //console.log(data);
  return (
    <>
      <header className={classes.header}>
        <Header variant={"primary"} personal />
      </header>
      <main className={classes.content} ref={contentEl} id="content">
        <div className={localClasses.root}>
          {data.Full_text_row.map(function({ title, body }, i) {
            return (
              <>
                <Container>
                  <h1>
                    <Typography variant={!i ? "heading" : "heading1"}>
                      {title}
                    </Typography>
                  </h1>
                </Container>
                <FullTextRow>{body}</FullTextRow>
              </>
            );
          })}
          <BannerImage
            topText={"Mornington eDeals"}
            header={true}
            headerText={data.Ads[0].title}
            markdown={data.Ads[0].body}
            description={
              "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
            }
            imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
            ctaButton
            ctaButtonProps={{
              children: data.Ads[0].button_text,
              onClick: function() {
                // alert("click");
              }
            }}
            gradient
            leftAlign={true}
          />
        </div>
      </main>
      <footer className={classes.footer} ref={footerEl}>
        <Footer />
      </footer>
    </>
  );
}

export default Legal;
