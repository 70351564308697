import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import MobileNavItem from "../MobileNavItem";
import MobileNavDropdown from "../MobileNavDropdown";
import posed from "react-pose";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      width: "100vw",
      height: "inherit",
      // backgroundColor: "red",
      left: 0,
      position: "absolute",
      overflow: "hidden",
      padding: "0px 16px 0px 16px",
      borderTop: "1px solid #CCCCCC"
    },
    menuSwitcher: {
      position: "relative",
      zIndex: 10,
      display: "flex",
      width: "100%",
      padding: "0px 0px 0px 0px",
      borderBottom: "1px solid #CCCCCC"
      // borderTop: "1px solid #CCCCCC"
    },
    submenu: {
      // display: "flex",
      // backgroundColor: "#E6E6E6 !important"
    },
    li: {
      display: "flex",
      position: "relative",
      flexDirection: "column",
      paddingLeft: "0",
      marginBottom: "0",
      listStyle: "none",
      zIndex: 0
    }
  };
});

const AnimatedLi = posed.li({
  closed: {
    top: -100,
    opacity: 0,
    flip: true,
    transition: {
      duration: 100,
      ease: "easeIn"
    }
  },
  open: {
    top: 0,
    opacity: 1,
    flip: true,
    transition: {
      duration: 200,
      ease: "easeIn"
    }
  }
});

function MobileNav2(props) {
  //console.log(props);

  const classes = useStyles(props);

  return (
    <div>
      {props.sections.map(function (item) {
        return (
          <div classes={classes.submenu}>
            <MobileNavItem>{item.content}</MobileNavItem>{" "}
          </div>
        );
      })}
    </div>
  );
}

const URL_LOOKUP = {
  "Which one is right for me?": "/in"
};

function MobileNav(props) {
  const classes = useStyles(props);
  //console.log(props);

  // const { isPersonal } = props;
  // const [openOption, setOpenOption] = useState("");
  const history = useHistory();

  const ip = !history.location.pathname.includes("/business");

  const [isPersonal, setPersonal] = useState(ip);

  return (
    <div className={classes.root}>
      <div className={classes.menuSwitcher}>
        <MobileNavItem
          active={isPersonal}
          variant={"primary"}
          large
          enableUnderline
          onClick={function () {
            setPersonal(true);
          }}
        >
          Personal
        </MobileNavItem>
        <MobileNavItem
          active={!isPersonal}
          variant={"tertiary"}
          large
          enableUnderline
          onClick={function () {
            setPersonal(false);
          }}
        >
          Business
        </MobileNavItem>
      </div>
      {isPersonal ? (
        <div>
          <li className={classes.li}>
            <MobileNavItem paddingLeft={0} variant={"primary"} large>
              <strong> Home</strong>
            </MobileNavItem>
          </li>

          {/* <AnimatedLi className={classes.li}> */}
          <MobileNavDropdown
            paddingLeft={0}
            large
            enableUnderline
            variant={"primary"}
            OptionsComponent={({ paddingLeft }) => (
              <>
                <MobileNavDropdown
                  paddingLeft={25}
                  OptionsComponent={({ paddingLeft }) =>
                    [
                      {
                        content: "Phones",
                        href: "/shop/mobile/phones",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/mobile/phones");
                        }
                      },
                      {
                        content: "Add-Ons",
                        href: "/shop/mobile/add-ons",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/mobile/add-ons");
                        }
                      }
                    ].map(function (item) {
                      return (
                        <li className={`${classes.li} ${classes.submenu}`}>
                          <MobileNavItem
                            paddingLeft={50}
                            variant={"primary"}
                            onClick={item.onClick}
                          >
                            <strong> {item.content} </strong>
                          </MobileNavItem>
                        </li>
                      );
                    })
                  }
                >
                  <strong>Mobile</strong>
                </MobileNavDropdown>

                <MobileNavDropdown
                  paddingLeft={25}
                  OptionsComponent={({ paddingLeft }) =>
                    [
                      {
                        content: "Which one is right for me?",
                        href: "shop/internet",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet");
                        }
                      },
                      {
                        content: "Fibre",
                        href: "/shop/internet/fibre",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet/fibre");
                        }
                      },
                      {
                        content: "DSL",
                        href: "/shop/internet/dsl",

                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet/dsl");
                        }
                      },
                      {
                        content: "Wireless",
                        href: "/shop/internet/wireless",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet/wireless");
                        }
                      },
                      {
                        content: "Web Hosting",
                        href: "/shop/internet/webhosting",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet/webhosting");
                        }
                      },
                      {
                        content: "Resell Internet",
                        href: "/shop/internet/resell",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/internet/resell");
                        }
                      }
                    ].map(function (item) {
                      return (
                        <li className={classes.li}>
                          <MobileNavItem
                            paddingLeft={50}
                            variant={"primary"}
                            onClick={item.onClick}
                          >
                            <strong> {item.content} </strong>
                          </MobileNavItem>
                        </li>
                      );
                    })
                  }
                >
                  <strong>Internet</strong>
                </MobileNavDropdown>

                <MobileNavDropdown
                  paddingLeft={25}
                  OptionsComponent={({ paddingLeft }) =>
                    [
                      {
                        content: "Packages",
                        href: "/shop/tv/packages",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/tv/packages");
                        }
                      },
                      {
                        content: "Digital Boxes",
                        href: "/shop/tv/digital-boxes",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/tv/digital-boxes");
                        }
                      },
                      {
                        content: "On Demand",
                        href: "/shop/tv/on-demand",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/tv/on-demand");
                        }
                      },
                      {
                        content: "TV Everywhere",
                        href: "/shop/tv/tv-everywhere",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/tv/tv-everywhere");
                        }
                      },
                      {
                        content: "Free Previews",
                        href: "/shop/tv/free-previews",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/tv/free-previews");
                        }
                      }
                    ].map(function (item) {
                      return (
                        <li className={classes.li}>
                          <MobileNavItem
                            paddingLeft={50}
                            variant={"primary"}
                            onClick={item.onClick}
                            href={item.href}
                          >
                            <strong> {item.content} </strong>
                          </MobileNavItem>
                        </li>
                      );
                    })
                  }
                >
                  <strong>TV</strong>
                </MobileNavDropdown>

                <MobileNavDropdown
                  paddingLeft={25}
                  OptionsComponent={({ paddingLeft }) =>
                    [
                      {
                        content: "Rates",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/home-phone/rates");
                        }
                      },
                      {
                        content: "Features",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/home-phone/features");
                        }
                      },
                      {
                        content: "Long Distance",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/home-phone/long-distance");
                        }
                      },
                      {
                        content: "International Calling Rates",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push(
                            "/shop/home-phone/international-calling-rates"
                          );
                        }
                      }
                    ].map(function (item) {
                      return (
                        <li className={classes.li}>
                          <MobileNavItem
                            paddingLeft={50}
                            variant={"primary"}
                            onClick={item.onClick}
                          >
                            <strong> {item.content} </strong>
                          </MobileNavItem>
                        </li>
                      );
                    })
                  }
                >
                  <strong>Home Phone</strong>
                </MobileNavDropdown>

                <MobileNavDropdown
                  paddingLeft={25}
                  OptionsComponent={({ paddingLeft }) =>
                    [
                      {
                        content: "Services",
                        href: "/shop/home-security/services",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/home-security/services");
                        }
                      },
                      {
                        content: "Installation",
                        href: "/shop/home-security/installation",
                        onClick: function (e) {
                          e.preventDefault();
                          history.push("/shop/home-security/installation");
                        }
                      }
                    ].map(function (item) {
                      return (
                        <li className={classes.li}>
                          <MobileNavItem
                            paddingLeft={50}
                            variant={"primary"}
                            onClick={item.onClick}
                            href={item.href}
                          >
                            <strong> {item.content} </strong>
                          </MobileNavItem>
                        </li>
                      );
                    })
                  }
                >
                  <strong>Home Security</strong>
                </MobileNavDropdown>
              </>
            )}
          >
            <strong>Shop</strong>
          </MobileNavDropdown>
          {/* </AnimatedLi> */}

          <MobileNavItem
            paddingLeft={0}
            large
            variant={"primary"}
            onClick={function (e) {
              history.push("/contact-us/live-support");
            }}
          >
            <strong>Live Support</strong>
          </MobileNavItem>

          <MobileNavItem
              paddingLeft={0}
              large
              variant={"tertiary"}
              href=""
              onClick={function (e) {
                // e.preventDefault();
                window.open('https://webmail.cyg.net','_blank');
              }}
            >
              <strong>WebMail</strong>
            </MobileNavItem>

          <MobileNavDropdown
            paddingLeft={0}
            large
            variant={"primary"}
            enableUnderline
            OptionsComponent={({ paddingLeft }) =>
              [
                {
                  content: "Get in Touch",
                  href: "/contact-us/get-in-touch",
                  onClick: function (e) {
                    e.preventDefault();
                    history.push("/contact-us/get-in-touch");
                  }
                },
                {
                  content: "Our Locations",
                  href: "/contact-us/our-locations",
                  onClick: function (e) {
                    e.preventDefault();
                    history.push("/contact-us/our-locations");
                  }
                },
                {
                  content: "Live Support",
                  href: "/contact-us/live-support",
                  onClick: function (e) {
                    e.preventDefault();
                    history.push("/contact-us/live-support");
                  }
                }
              ].map(function (item) {
                return (
                  <li className={classes.li}>
                    <MobileNavItem
                      paddingLeft={25}
                      variant={"primary"}
                      onClick={item.onClick}
                      href={item.href}
                    >
                      <strong> {item.content} </strong>
                    </MobileNavItem>
                  </li>
                );
              })
            }
          >
            <strong>Contact us</strong>
          </MobileNavDropdown>

          <MobileNavDropdown
            paddingLeft={0}
            large
            variant={"primary"}
            enableUnderline
            OptionsComponent={({ paddingLeft }) =>
              [
                {
                  content: "My Bills",
                  href: "/about-us/my-bills",
                  onClick: function (e) {
                    //e.preventDefault();
                    window.open('https://mornington.smarthub.coop/Login.html','_blank');
                  }
                },
                {
                  content: "Pay Now",
                  href: "/about-us/pay-now",
                  onClick: function (e) {
                    e.preventDefault();
                    window.open('https://mornington.smarthub.coop/PayNow.html','_blank');
                  }
                }
              ].map(function (item) {
                return (
                  <li className={classes.li}>
                    <MobileNavItem
                      paddingLeft={25}
                      variant={"primary"}
                      onClick={item.onClick}
                      href={item.href}
                    >
                      <strong> {item.content} </strong>
                    </MobileNavItem>
                  </li>
                );
              })
            }
          >
            <strong>My Account</strong>
          </MobileNavDropdown>


        </div>
      ) : (
          <div>
            <li className={classes.li}>
              <MobileNavItem paddingLeft={0} variant={"tertiary"} large>
                <strong> Home</strong>
              </MobileNavItem>
            </li>

            {/* <AnimatedLi className={classes.li}> */}
            <MobileNavDropdown
              paddingLeft={0}
              large
              enableUnderline
              variant={"tertiary"}
              OptionsComponent={({ paddingLeft }) => (
                <>
                  <MobileNavDropdown
                    paddingLeft={25}
                    OptionsComponent={({ paddingLeft }) =>
                      [
                        {
                          content: "Fibre",
                          href: "/business/shop/internet/fibre",
                          onClick: function (e) {
                            e.preventDefault();
                            history.push("/business/shop/internet/fibre");
                          }
                        },
                        {
                          content: "DSL",
                          href: "/business/shop/internet/dsl",
                          onClick: function (e) {
                            e.preventDefault();
                            history.push("/business/shop/internet/dsl");
                          }
                        },
                        {
                          content: "Wireless Internet",
                          href: "/business/shop/internet/wireless-internet",
                          onClick: function (e) {
                            e.preventDefault();
                            history.push(
                              "/business/shop/internet/wireless-internet"
                            );
                          }
                        },
                        {
                          content: "Circuits Internet",
                          href: "/business/shop/internet/circuits-internet",
                          onClick: function (e) {
                            e.preventDefault();
                            history.push(
                              "/business/shop/internet/circuits-internet"
                            );
                          }
                        },
                        {
                          content: "Web Hosting",
                          href: "/business/shop/internet/web-hosting",
                          onClick: function (e) {
                            e.preventDefault();
                            history.push(
                              "/business/shop/internet/web-hosting"
                            );
                          }
                        }
                      ].map(function (item) {
                        return (
                          <li className={`${classes.li} ${classes.submenu}`}>
                            <MobileNavItem
                              paddingLeft={50}
                              variant={"tertiary"}
                              onClick={item.onClick}
                            >
                              <strong> {item.content} </strong>
                            </MobileNavItem>
                          </li>
                        );
                      })
                    }
                  >
                    <strong>Internet</strong>
                  </MobileNavDropdown>

                  <MobileNavDropdown
                    paddingLeft={25}
                    OptionsComponent={({ paddingLeft }) =>
                      [
                        {
                          content: "Services",
                          href: "/business/shop/telephone/services",
                          onClick: function (e) {
                            e.preventDefault();
                            history.push("/business/shop/telephone/services");
                          }
                        }
                      ].map(function (item) {
                        return (
                          <li className={classes.li}>
                            <MobileNavItem
                              paddingLeft={50}
                              variant={"tertiary"}
                              onClick={item.onClick}
                              href={item.href}
                            >
                              <strong> {item.content} </strong>
                            </MobileNavItem>
                          </li>
                        );
                      })
                    }
                  >
                    <strong>Telephone</strong>
                  </MobileNavDropdown>

                  <MobileNavDropdown
                    paddingLeft={25}
                    OptionsComponent={({ paddingLeft }) =>
                      [
                        {
                          content: "Mobility Solutions",
                          onClick: function (e) {
                            e.preventDefault();
                            history.push(
                              "/business/shop/mobile/mobility-solutions"
                            );
                          }
                        }
                      ].map(function (item) {
                        return (
                          <li className={classes.li}>
                            <MobileNavItem
                              paddingLeft={50}
                              variant={"tertiary"}
                              onClick={item.onClick}
                            >
                              <strong> {item.content} </strong>
                            </MobileNavItem>
                          </li>
                        );
                      })
                    }
                  >
                    <strong>Mobile</strong>
                  </MobileNavDropdown>

                  <MobileNavDropdown
                    paddingLeft={25}
                    OptionsComponent={({ paddingLeft }) =>
                      [
                        {
                          content: "Services",
                          href: "/business/shop/business-security/services",
                          onClick: function (e) {
                            e.preventDefault();
                            history.push(
                              "/business/shop/business-security/services"
                            );
                          }
                        },
                        {
                          content: "Installation",
                          href:
                            "/business/shop/business-security/installation",
                          onClick: function (e) {
                            e.preventDefault();
                            history.push(
                              "/business/shop/business-security/installation"
                            );
                          }
                        }
                      ].map(function (item) {
                        return (
                          <li className={classes.li}>
                            <MobileNavItem
                              paddingLeft={50}
                              variant={"tertiary"}
                              onClick={item.onClick}
                              href={item.href}
                            >
                              <strong> {item.content} </strong>
                            </MobileNavItem>
                          </li>
                        );
                      })
                    }
                  >
                    <strong>Business Security</strong>
                  </MobileNavDropdown>
                </>
              )}
            >
              <strong>Shop</strong>
            </MobileNavDropdown>
            {/* </AnimatedLi> */}

            <MobileNavItem
              paddingLeft={0}
              large
              variant={"tertiary"}
              href="/contact-us/live-support"
              onClick={function (e) {
                // e.preventDefault();
                history.push("/contact-us/live-support");
              }}
            >
              <strong>Live Support</strong>
            </MobileNavItem>

            <MobileNavItem
              paddingLeft={0}
              large
              variant={"tertiary"}
              href=""
              onClick={function (e) {
                // e.preventDefault();
                window.open('https://webmail.cyg.net','_blank');
              }}
            >
              <strong>WebMail</strong>
            </MobileNavItem>


            <MobileNavDropdown
              paddingLeft={0}
              large
              enableUnderline
              variant={"tertiary"}
              OptionsComponent={({ paddingLeft }) =>
                [
                  {
                    content: "Get in Touch",
                    href: "/contact-us/get-in-touch",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/contact-us/get-in-touch");
                    }
                  },
                  {
                    content: "Our Locations",
                    href: "/contact-us/our-locations",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/contact-us/our-locations");
                    }
                  },
                  {
                    content: "Live Support",
                    href: "/contact-us/live-support",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/contact-us/live-support");
                    }
                  }
                ].map(function (item) {
                  return (
                    <li className={classes.li}>
                      <MobileNavItem
                        paddingLeft={25}
                        variant={"tertiary"}
                        onClick={item.onClick}
                        href={item.href}
                      >
                        <strong> {item.content} </strong>
                      </MobileNavItem>
                    </li>
                  );
                })
              }
            >
              <strong>Contact us</strong>
            </MobileNavDropdown>

            <MobileNavDropdown
            paddingLeft={0}
            large
            variant={"primary"}
            enableUnderline
            OptionsComponent={({ paddingLeft }) =>
              [
                {
                  content: "My Bills",
                  href: "/about-us/my-bills",
                  onClick: function (e) {
                    //e.preventDefault();
                    window.open('https://mornington.smarthub.coop/Login.html','_blank');
                  }
                },
                {
                  content: "Pay Now",
                  href: "/about-us/pay-now",
                  onClick: function (e) {
                    e.preventDefault();
                    window.open('https://mornington.smarthub.coop/PayNow.html','_blank');
                  }
                }
              ].map(function (item) {
                return (
                  <li className={classes.li}>
                    <MobileNavItem
                      paddingLeft={25}
                      variant={"primary"}
                      onClick={item.onClick}
                      href={item.href}
                    >
                      <strong> {item.content} </strong>
                    </MobileNavItem>
                  </li>
                );
              })
            }
          >
            <strong>My Account</strong>
          </MobileNavDropdown>


          </div>
        )}
    </div>
  );
}

MobileNav.propTypes = {
  isPersonal: PropTypes.bool
};

MobileNav.defaultProps = {
  isPersonal: true
};

export default MobileNav;
