import React from "react";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import ProductbarItem from "../ProductbarItem";
import ProductbarSubMenu from "../ProductbarSubMenu";
import ProductbarIconSubMenu from "../ProductbarIconSubMenu";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.white.contrast,
      width: "100%",
      padding: "0px 60px 0px 60px",
      borderBottom: "1px solid #CCCCCC"
    },

    content: {
      display: "flex",
      alignItems: "center",
      // justifyContent: "flex-end",
      padding: "0 60px 0 60px",
      margin: 0,
      padding: 0
      // position: "relative",
    },
    logo: {
      width: "180px",
      height: "auto",
      // justifySelf: "flex-start",
      marginRight: "auto",
      listStyleType: "none"
    }
  };
});

export default function ProductBarDesktop(props) {
  const classes = useStyles(props);
  const history = useHistory();
  //console.log(history.location.pathname);
  return (
    <>
      <nav
        className={classes.root}
        aria-label="Mornington communications Main Navigation Menu"
      >
        <ul className={classes.content} role="menu">
          <li className={classes.logo} role="menuitem">
            <a href="/">
              <img
                src="/assets/images/mornington-colour.svg"
                alt="Mornington communications logo"
              />
            </a>
          </li>
          <ProductbarItem
            active={history.location.pathname === "/"}
            {...props}
            onClick={function (e) {
              e.preventDefault();
              history.push("/");
            }}
          >
            Home
          </ProductbarItem>

          <ProductbarItem
            active={history.location.pathname.includes("/shop")}
            dropDown
            sections={[
              {
                title: "Mobile",
                links: [
                  {
                    content: "Phones",
                    href: "/shop/mobile/phones",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/mobile/phones");
                    }
                  },
                  {
                    content: "Add-Ons",
                    href: "/shop/mobile/add-ons",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/mobile/add-ons");
                    }
                  },
                  {
                    content: (
                      <>
                        Coverage Map <i className="fas fa-external-link-alt"></i>
                      </>
                    ),
                    href: "https://www.bell.ca/Mobility/Our_network_coverage",
                    target: "_blank"
                  }                  
                ]
              },
              {
                title: "Internet",
                links: [
                  {
                    content: "Which one is right for me?",
                    href: "/shop/internet",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/internet");
                    }
                  },
                  {
                    content: "Fibre",
                    href: "/shop/internet/fibre",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/internet/fibre");
                    }
                  },
                  {
                    content: "DSL",
                    href: "/shop/internet/dsl",

                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/internet/dsl");
                    }
                  },
                  {
                    content: "Wireless",
                    href: "/shop/internet/wireless",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/internet/wireless");
                    }
                  },
                  {
                    content: "Web Hosting",
                    href: "/shop/internet/webhosting",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/internet/webhosting");
                    }
                  },
                  {
                    content: "Resell Internet",
                    href: "/shop/internet/resell",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/internet/resell");
                    }
                  }
                ]
              },
              {
                title: "TV",
                links: [
                  {
                    content: "Packages",
                    href: "/shop/tv/packages",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/tv/packages");
                    }
                  },
                  {
                    content: "Digital Boxes",
                    href: "/shop/tv/digital-boxes",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/tv/digital-boxes");
                    }
                  },
                  {
                    content: "On Demand",
                    href: "/shop/tv/on-demand",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/tv/on-demand");
                    }
                  },
                  {
                    content: "TV Everywhere",
                    href: "/shop/tv/tv-everywhere",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/tv/tv-everywhere");
                    }
                  },
                  {
                    content: "Free Previews",
                    href: "/shop/tv/free-previews",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/tv/free-previews");
                    }
                  }
                ]
              },
              {
                title: "Home Phone",
                links: [
                  {
                    content: "Rates",
                    href: "/shop/home-phone/rates",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/home-phone/rates");
                    }
                  },
                  {
                    content: "Features",
                    href: "/shop/home-phone/features",

                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/home-phone/features");
                    }
                  },
                  {
                    content: "Long Distance",
                    href: "/shop/home-phone/long-distance",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/home-phone/long-distance");
                    }
                  },
                  {
                    content: "International Calling Rates",
                    href: "/shop/home-phone/international-calling-rates",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push(
                        "/shop/home-phone/international-calling-rates"
                      );
                    }
                  }
                ]
              },
              {
                title: "Home Security",
                links: [
                  {
                    content: "Services",
                    href: "/shop/home-security/services",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/home-security/services");
                    }
                  },
                  {
                    content: "Installation",
                    href: "/shop/home-security/installation",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/shop/home-security/installation");
                    }
                  }
                ]
              }
            ]}
            {...props}
            Submenu={ProductbarSubMenu}
            caret
          >
            Shop
          </ProductbarItem>

          <ProductbarItem
            active={history.location.pathname.includes("/live-support")}
            onClick={function (e) {
              e.preventDefault();
              history.push("/contact-us/live-support");
            }}
            {...props}
          >
            Live Support
          </ProductbarItem>
          <ProductbarItem
             //active={"https://webmail.cyg.net"}
            onClick={function (e) {
              e.preventDefault();
              window.open('https://webmail.cyg.net','_blank');
            }}
            {...props}
          >
            Webmail <i className="fas fa-external-link-alt"></i>
          </ProductbarItem>

          <ProductbarItem
            active={
              history.location.pathname.includes("/contact-us") &&
              !history.location.pathname.includes("/live-support")
            }
            dropDown
            caret
            sections={[
              {
                links: [
                  {
                    content: "Get in Touch",
                    href: "/contact-us/get-in-touch",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/contact-us/get-in-touch");
                    }
                  },
                  {
                    content: "Our Locations",
                    href: "/contact-us/our-locations",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/contact-us/our-locations");
                    }
                  },
                  {
                    content: "Live Support",
                    href: "/contact-us/live-support",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/contact-us/live-support");
                    }
                  }
                ]
              }
            ]}
            {...props}
            Submenu={ProductbarSubMenu}
          >
            Contact Us{" "}
          </ProductbarItem>
          <ProductbarItem
            {...props}
            dropDown
            noHover
            Submenu={ProductbarIconSubMenu}
          >
            <i class="fas fa-search"></i>
          </ProductbarItem>

          <ProductbarItem
            {...props}
            dropDown
            noHover
            Submenu={ProductbarIconSubMenu}
          >
            <i class="fas fa-location-arrow"></i>{" "}
          </ProductbarItem>
        </ul>

        {/* <ProductbarIconSubMenu /> */}
      </nav>
    </>
  );
}
