import React, { useState, useCallback, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import Modal from "components/Modal";

import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;
  const history = useHistory();
  const plans = data.Plan_Details.plans;
  const hash = history.location.hash;

  //console.log("PLANS", history.location);
  const [open, setOpen] = useState(hash === "#modal" || false);
  const cb = useCallback(function(e) {
    if (e.newURL.includes("#modal")) {
      setOpen(true);
    }
  });

  useEffect(function() {
    window.addEventListener("hashchange", cb);
    return function() {
      window.removeEventListener("hashchange", cb);
    };
  });
  // alert("this");
  return (
    <>
      <div className={classes.root}>
        <BannerImage
          topText={data.mainBanner[0]?.breadcrumb}
          title={data.mainBanner[0]?.title}
          markdown={data.mainBanner[0]?.body}
          imageUrl={getStrapiURL(data.mainBanner[0]?.image.src[0].url)}
          gradient
        />
        <PriceGrid
          plans={data.Plan_Details[0].plans}
          titleText={data.Plan_Details[0].title}
          hasTitle
          location={location}
          onSelection={setLocation}
          hasDisclaimer={true}
          //ctaText={data.Plan_Details[0].button_text}
          //BASED ON REQUEST RECEIVED JUNE 2 2020
          ctaText={ sessionStorage.getItem("location")==='Milverton' || (sessionStorage.getItem("location")==='Other areas' || data.Plan_Details[0].plans.length===0) ?
          data.Plan_Details[0].button_text:'Sign Up Now'}
          //Redirect to smarthub based on feedback
          onClick={()=>sessionStorage.getItem("location")==='Milverton' || (sessionStorage.getItem("location")==='Other areas' || data.Plan_Details[0].plans.length===0) ?
          history.push("/contact-us/get-in-touch"): window.open("https://mornington.smarthub.coop/Shop.html", "_blank")}
          // TODO add disclaimer to strapi
          disclaimer={data.Plan_Details[0].note}
        />
        <PriceGrid
          plans={data.Plan_Details[1].plans}
          titleText={data.Plan_Details[1].title}
          hasTitle
          location={location}
          onSelection={setLocation}
          hasDisclaimer={true}
          hasLocation={false}
          //ctaText={data.Plan_Details[1].button_text}
           //BASED ON REQUEST RECEIVED JUNE 2 2020
           ctaText={ sessionStorage.getItem("location")==='Milverton' || (sessionStorage.getItem("location")==='Other areas' || data.Plan_Details[1].plans.length===0)?
           data.Plan_Details[1].button_text:'Sign Up Now'}
           //Redirect to smarthub based on feedback
           onClick={()=>sessionStorage.getItem("location")==='Milverton' || (sessionStorage.getItem("location")==='Other areas' || data.Plan_Details[1].plans.length===0)?
           history.push("/contact-us/get-in-touch"): window.open("https://mornington.smarthub.coop/Shop.html", "_blank")}
          // TODO add disclaimer to strapi
          disclaimer={data.Plan_Details[1].note}
        />
        <PriceGrid
          plans={data.Plan_Details[2].plans}
          titleText={data.Plan_Details[2].title}
          hasTitle
          location={location}
          onSelection={setLocation}
          hasDisclaimer={true}
          hasLocation={false}
          //ctaText={data.Plan_Details[2].button_text}
           //BASED ON REQUEST RECEIVED JUNE 2 2020
           ctaText={ sessionStorage.getItem("location")==='Milverton' || (sessionStorage.getItem("location")==='Other areas' || data.Plan_Details[2].plans.length===0)?
           data.Plan_Details[2].button_text:'Sign Up Now'}
           //Redirect to smarthub based on feedback
           onClick={()=>sessionStorage.getItem("location")==='Milverton' || (sessionStorage.getItem("location")==='Other areas' || data.Plan_Details[2].plans.length===0)?
           history.push("/contact-us/get-in-touch"): window.open("https://mornington.smarthub.coop/Shop.html", "_blank")}
          // TODO add disclaimer to strapi
          disclaimer={data.Plan_Details[2].note}
        />
        <PriceGrid
          plans={data.Plan_Details[3].plans}
          titleText={data.Plan_Details[3].title}
          hasTitle
          location={location}
          onSelection={setLocation}
          hasDisclaimer={true}
          hasLocation={false}
          //ctaText={data.Plan_Details[3].button_text}
           //BASED ON REQUEST RECEIVED JUNE 2 2020
           ctaText={ sessionStorage.getItem("location")==='Milverton' || (sessionStorage.getItem("location")==='Other areas'||data.Plan_Details[3].plans.length===0) ?
           data.Plan_Details[3].button_text:'Sign Up Now'}
           //Redirect to smarthub based on feedback
           onClick={()=>sessionStorage.getItem("location")==='Milverton' || (sessionStorage.getItem("location")==='Other areas'||data.Plan_Details[3].plans.length===0) ?
           history.push("/contact-us/get-in-touch"): window.open("https://mornington.smarthub.coop/Shop.html", "_blank")}
          // TODO add disclaimer to strapi
          disclaimer={data.Plan_Details[3].note}
        />

        <BannerImage
          header={true}
          headerText={data.Ads[0].title}
          markdown={data.Ads[0].body}
          imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
          ctaButton
          ctaButtonProps={{
            children: data.Ads[0].button_text,
            onClick: function() {
              history.push("/bundle-and-save");
            }
          }}
          gradient
          leftAlign={true}
        />
      </div>

      <Modal>{data.Modal[0].Content}</Modal>
    </>
  );
}
export default View;
