import { useQuery } from "@apollo/react-hooks";
import Skeleton from "components/Spinner";
import gql from "graphql-tag";
import React from "react";
import { useHistory } from "react-router-dom";
import View from "./view";

const GET_FIBRES = gql`
  # Write your query or mutation here
  query getPhone($where: JSON) {
    content: phones {
      Ads {
        title
        body
        button_text
        images {
          title: image_title
          src {
            url
          }
          alt_text
          type
        }
      }
    }
    products(where: $where) {
      description
      os
      phoneBrand: manufacturer
      price
      phoneModel: title
      image_src
      plans: Phone_plans {
        planName: Title
        planDetails: Details
        planPrice: Price
      }
      Full_Image_Row {
        Title
        Image_mobile {
          url
        }
        Image_desktop {
          url
        }
        alt_text
      }
    }
  }
`;

function getPhoneName(h) {
  let s = h.location.pathname.split("/shop/mobile/phone")[1];

  return s.length ? unescape(s.substring(1)) : " ";
}

function GraphContainer() {
  const history = useHistory();

  const parsedName = getPhoneName(history);
  //console.log("parsedName", parsedName);

  const phoneNameFilter = { id: parsedName };
  const { loading, error, data } = useQuery(GET_FIBRES, {
    variables: { where: phoneNameFilter }
  });

  //console.log("GQL", loading, error, data);

  if (parsedName === " " || (!loading && !error && !data.products.length)) {
    history.push("/404");
  }
  return loading || error || !data.products.length ? (
    <Skeleton />
  ) : (
      <View
        phoneName={parsedName || ""}
        //   setLocation={setLocation}
        data={{ content: data.content[0], products: data.products }}
      ></View>
    );
}

export default GraphContainer;
