import React from "react";
import Header from "components/Header";
import ProductCard from "components/ProductCard";
import ImageSlider from "components/ImageSlider";
import ImageRows from "components/ImageRows";
import Footer from "components/Footer";
import BannerImage from "components/BannerImage";
import usePage from "hooks/usePage";
import getStrapiURL from "utils/getStrapiURL";
import { useHistory } from "react-router-dom";

function Home(props) {
  const [pageClasses, contentEl, footerEl] = usePage();
  const history = useHistory();
  const { data } = props;
  return (
    <>
      <header className={pageClasses.header}>
        <Header variant={"primary"} personal />
      </header>

      <main className={pageClasses.content} ref={contentEl} id="content">
        <ImageSlider
          images={data.mainBanner.map(function (item, i) {
            return {
              imageUrl: getStrapiURL(item.image.src[0].url),
              markdown: item.body,
              ctaButton: item.button_text.length,
              ctaButtonProps: {
                children: item.button_text,
                onClick: function () {
                  //CHANGE CTA HERE FOR BANNER IMAGES
                  if (item.body.toLowerCase().includes("covid")) {
                    window.open(getStrapiURL("/uploads/ee4206b7114948928475ca3bdd487aff.pdf"), "_blank");
                  } else if (item.body.toLowerCase().includes("delivery")) {
                    //window.open(getStrapiURL("/uploads/7932090a135c44158410ea0b6f3fad26.pdf"), "_blank");
                    history.push("/contact-us/get-in-touch");
                  } else if (item.body.toLowerCase().includes("meeting")) {
                    window.open(getStrapiURL("/uploads/09e6040c266247cb9683c64bff22ebe0.pdf"), "_blank");
                  } else if (item.body.toLowerCase().includes("black friday")) {
                    window.open(getStrapiURL("/uploads/3d813de35c2342afbc2b54bdc239a8bb.pdf"), "_blank");
                  } else if (item.body.toLowerCase().includes("security")) {
                    history.push("/shop/home-security/services");
                  }

                }
              },
              textColor: "#000",
              gradient: i !== data.mainBanner.length - 1,
              gradientColor: "#fff"
            };
          })}
        />
        <ProductCard cards={[
          {
            productImageUrl: "/assets/images/productCards/wireless.svg",
            productTitle: "Mobile",
            productDescription: "Mornington offers all types of phone plans and phones - find yours now.",
            linkText: "Explore Phones",
            onClick: function () {
              history.push("/shop/mobile/phones");
            },
            productWidth: "58px",
            productHeight: "100px"
          },
          {
            productImageUrl: "/assets/images/productCards/new-internet.svg",
            productTitle: "Internet",
            productDescription: "Bringing you the latest technology, fastest speeds and highest quality.",
            linkText: "Find Your Plan",
            onClick: function () {
              // alert("click");
              history.push("/shop/internet")

            },
            productWidth: "100px",
            productHeight: "67px"
          },
          {
            productImageUrl: "/assets/images/productCards/tv.svg",
            productTitle: "TV",
            productDescription: "Watch TV everywhere for free with your Mornington TV account name and number.",
            linkText: "Discover Your Channels",
            onClick: function () {
              history.push("/shop/tv/packages");
            },
          },
          {
            productImageUrl: "/assets/images/productCards/home-phone.svg",
            productTitle: "Home Phone",
            productDescription:
              "Fully-customizable home phone packages to suit all of your telephoning needs.",
            linkText: "Choose Your Package",
            onClick: function () {
              // alert("click");

              history.push("/shop/home-phone/rates")
            },
            productWidth: "100px",
            productHeight: "77px"
          },
          {
            productImageUrl: "/assets/images/productCards/home-security.svg",
            productTitle: "Home Security",
            productDescription: "Mornington provides a complete solution to all of your security needs.",
            linkText: "Secure Your Home",
            onClick: function () {
              history.push("/shop/home-security/services");
            },
            productWidth: "100px",
            productHeight: "67px"
          }
        ]}
        />
        <ImageRows
          rows={[
            {
              imageLeft: true,
              imageSrc: getStrapiURL(
                data.Left_Image_Row[0]?.images[0].src[0].url
              ),
              description:
                "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
              linkText: "",
              conditions: "",
              content: data.Left_Image_Row[0]?.body,
              onClick: function () {
                ////  alert("click") ;
              }
            },
            {
              imageLeft: false,
              imageSrc: getStrapiURL(
                data.Right_Image_Row[0]?.images[0].src[0].url
              ),
              description:
                "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
              linkText: "",
              conditions: "",
              content: data.Right_Image_Row[0]?.body,
              onClick: function () {
                ////  alert("click") ;
              }
            },
            {
              imageLeft: true,
              imageSrc: getStrapiURL(
                data.Left_Image_Row[1]?.images[0].src[0].url
              ),
              description:
                "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
              linkText: "",
              conditions: "",
              content: data.Left_Image_Row[1]?.body,
              onClick: function () {
                ////  alert("click") ;
              }
            },
            {
              imageLeft: false,
              imageSrc: getStrapiURL(
                data.Right_Image_Row[1]?.images[0].src[0].url
              ),
              description:
                "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
              linkText: "",
              conditions: "",
              content: data.Right_Image_Row[1]?.body,
              onClick: function () {
                ////  alert("click") ;
              }
            }
          ]}
        />
        {/* <div className={classes.row}></div> */}
        <BannerImage
          topText={"Mornington Deals"}
          header={true}
          headerText={data.Ads[0].title}
          markdown={data.Ads[0].body}
          description={
            "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
          }
          imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
          ctaButton
          ctaButtonProps={{
            children: data.Ads[0].button_text,
            onClick: function () {
              history.push("/contact-us/live-support");
            }
          }}
          gradient
          leftAlign={true}
        />
      </main>

      <footer className={pageClasses.footer} ref={footerEl}>
        <Footer />
      </footer>
    </>
  );
}

export default Home;
